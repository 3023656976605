header {
  position: sticky;
  top: 0;
  height: 50px;
  background-color: #E77056;
  z-index: 2;
}

header > div#logo {
  display: inline-block;
  padding: 10px 10px 10px 50px;
}

header > div#navigation {
  float: right;
  padding: 10px 50px 10px 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  font-style: normal;
  font-weight: 600;
}

header > div#navigation > a {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  padding: 0 10px;
}

section:not(#carousel) {
  padding: 50px 350px;
}

.item {
  display: inline-block;
  padding: 0 75px 0 0;
  width: 25%;
  vertical-align: top;
}

.item > img {
  height: 100px;
}

.item > div > .itemTitle {
  display: block;
  font-weight: 900;
}

section#aboutus {
  background-color: #f2f3f4;
}

section#contact button {
  margin: 10px 0;
  float: right;
}

section#contact .formGroup {
  margin: 10px 0;
}

#emailsent {
  float: left;
  color: green;
}